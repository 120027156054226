<template>
    <b-container fluid class="form1 ">
        <b-container>
            <b-row>
                <b-col lg="6">
                    <img src="../img/formF3.svg" class="formF3" alt="">
                    <div style="z-index:3!important">
                        <p class="block3-p1 text-left mt-5 pt-4" style="color:#fff">contact us</p>
                        <h2 class="text-left formh2">Get in touch with <br> Pennsylvania Logistics</h2>
                        <p class="text-left formP pt-3">Any questions left? Get a free consultation <br> from our responsive
                            specialists.</p>
                        <b-row class="text-left pt-3">
                            <b-col cols="1">
                                <img src="../img/formMs.svg" width="35" height="35" alt="">
                            </b-col>
                            <b-col cols="11" class="formLi">
                                <a href="mailto:dispatch@pennsylvanialogisticsllc.com" target="_blank"
                                    class="RL1 ml-2">dispatch@pennsylvanialogisticsllc.com</a>
                            </b-col>
                        </b-row>
                        <b-row class="text-left pt-2">
                            <b-col cols="1">
                                <img src="../img/formPh.svg" width="27" height="27" style="margin-left:5px;margin-top:4px"
                                    alt="">
                            </b-col>
                            <b-col cols="11" class="formLi">
                                <a href="tel:+1 (267) 507 7077" class="RL1">
                                    <div class="ml-2">267-507-70-77</div>
                                </a>
                            </b-col>
                        </b-row>
                        <b-row class="text-left pt-2">
                            <b-col cols="1" style="display:flex;align-items:center;gap:8px">
                                <a href="https://www.facebook.com/profile.php?id=100093159140414&mibextid=LQQJ4d"
                                    target="_blank">
                                    <img src="../img/formFb.svg" class="pt-1 social-icon" width="35" height="35" alt="">
                                </a>
                                <a href="https://www.instagram.com/pennsylvania.logistics/?igshid=NTc4MTIwNjQ2YQ%3D%3D"
                                    target="_blank">
                                    <img src="../img/instagram.svg" class="pt-1 social-icon" width="35" height="35" alt="">
                                </a>
                                <a href="https://www.google.com/search?tbs=lf:1,lf_ui:2&tbm=lcl&q=pennsylvania+logistics+llc&rflfq=1&num=10&sa=X&ved=2ahUKEwj1kua5z_z_AhVQGRAIHbzfBg8QjGp6BAgrEAE&biw=1658&bih=1275&dpr=2#rlfi=hd:;si:14142921212813383822,l,ChpwZW5uc3lsdmFuaWEgbG9naXN0aWNzIGxsY0jmoePUsq-AgAhaKBABEAIYABgBIhpwZW5uc3lsdmFuaWEgbG9naXN0aWNzIGxsYzICZW6SARFsb2dpc3RpY3Nfc2VydmljZaoBVhABKhEiDWxvZ2lzdGljcyBsbGMoADIfEAEiG4ZBf8VZfzKdTI0CY-plHGzsNiJGhbuGUckynjIeEAIiGnBlbm5zeWx2YW5pYSBsb2dpc3RpY3MgbGxj;mv:[[42.2338819,-74.6719562],[39.5882502,-80.55186979999999]]"
                                    target="_blank">
                                    <img src="../img/google.svg" class="pt-1 social-icon" width="35" height="35" alt="">
                                </a>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col lg="6">
                    <img src="../img/formF1.svg" class="formF1" alt="">
                    <img src="../img/formF2.svg" class="formF2" alt="">
                    <b-card class="form-card form-mg-m">
                        <b-form @submit.prevent="sendData">
                            <b-form-group class="mt-3">
                                <b-form-input placeholder="Name" v-model="name" class="form-input"></b-form-input>
                            </b-form-group>
                            <b-form-group class="pt-3">
                                <b-form-input placeholder="E-mail" v-model="email" class="form-input"></b-form-input>
                            </b-form-group>
                            <b-form-group class="pt-3">
                                <b-form-input placeholder="Phone" v-model="phone" class="form-input"></b-form-input>
                            </b-form-group>
                            <p class="text-left pt-3 pl-2 ml-1 block8-p">Questions /comments</p>
                            <b-form-group>
                                <b-form-textarea no-resize rows="3" v-model="comment"
                                    class="form-textarea"></b-form-textarea>
                            </b-form-group>
                            <b-row class="ml-1">
                                <b-form-checkbox v-model="check"></b-form-checkbox>
                                <p>I agree with <router-link to="/privacy-policy" class="RL">privacy policy</router-link>.
                                </p>
                            </b-row>
                            <b-row class="ml-1 mt-3 mb-4">
                                <b-button class="btn-primery" style="width:241px" type="submit">Contact us</b-button>
                            </b-row>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import { httpClient } from '../HttpClientV1/http-client'
export default {
    data() {
        return {
            name: null,
            comment: null,
            email: null,
            phone: null,
            check: true,
        }
    },
    methods: {

        sendData() {
            var data = {
                form_request: {
                    name: this.name,
                    quest: this.comment,
                    email: this.email,
                    phone: this.phone,
                }
            }
            if (this.check) {
                httpClient
                    .post('api/form_request', data)
                    .then(() => {
                        this.name = this.comment = this.email = this.phone = null
                        this.$router.push('/thank-you')
                    })
            }
            else {
                alert('Place agree with privacy policy')
            }
        }
    }
}
</script>
