<template>
    <b-container fluid class="block8">
        <b-container>
             <b-row class="d-flex justify-content-center">
                <p class="block3-p1 ">blog</p>
            </b-row>
            <b-row class="d-flex justify-content-center">
                <h2 class="block2-h2">Related articles and news</h2>
            </b-row>
            <b-row class="text-left mt-5">
                <b-col md="4" style="cursor:pointer" class="mb-4" v-for="(item, index) in Articles" :key="index" @click="loadArticle(item.slug)">
                    <img :src="'https://pennlogisticsllc.com/' + item.cover" style="width:100%" alt="">
                    <h3 class="block4-h3 mt-3">
                        {{item.title}}
                    </h3>
                    <p class="block4-p">{{item.content.replaceAll(/(\<(\/?[^>]+)>)/g, '').slice(0,100)}} </p>
                </b-col>
<!--                 <b-col md="4">
                    <img src="../img/block8-card.png" alt="">
                    <h3 class="block8-h3 mt-3">
                        Article 2
                    </h3>
                    <p class="block8-p">The Frankfurter Buchmesse has a tradition spanning more than 500 years. </p>
                </b-col>
                 <b-col md="4">
                    <img src="../img/block8-card.png" alt="">
                    <h3 class="block8-h3 mt-3">
                        Article 3
                    </h3>
                    <p class="block8-p">The Frankfurter Buchmesse has a tradition spanning more than 500 years. </p>
                </b-col>
                --->
            </b-row>
            <b-row class="d-flex justify-content-center mt-5">
                <b-button class="btn-primery" @click="allArticles">All articles</b-button>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    methods:{
        ...mapActions(['fetchArticles']),
        allArticles(){
            this.$router.push('/blog')
        },
        loadArticle(slug){
          this.$router.push('/blog/'+ slug)
        }
    },
    mounted(){
        this.fetchArticles(3)
    },
    computed:{
        ...mapGetters(['getArticles']),
        Articles(){
            return this.getArticles
        }
    }
}
</script>